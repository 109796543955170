const colors = ["#01FFc3", "#74ee15", "#FFB3FD", "#9D72FF"]

export function colorSchemeArray() {
    let shuffledColors = colors
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

    return shuffledColors
}

export function getRandomColorFromScheme() {
    const randomColorIndex = Math.floor(Math.random() * 100) % colors.length
    return colors[randomColorIndex]
}